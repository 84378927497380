<template>
    <div @mouseleave="focusout">
        <div class="filter-container">
            <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                <template #button-content>
                    <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                    class="icon icon-keyboard"></i></span>
                </template>
                <div>
                    <div class="top-content">
                        <span>Параметры фильтра</span>
                        <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                    </div>
                    <div class="filter-block">
                        <b-form-group label="Плановый период">
                            <b-form-select
                                v-model="repYear"
                                :options="listOfYears"
                                @change="changeFilter"
                                ref="curYearRef"
                            >
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-group label="Область/район">
                            <b-form-select
                                v-model="region"
                                :options="listReg"
                                value-field="code"
                                @change="changeFilter"
                                ref="regionRef"
                            >
                                <template #first>
                                    <b-form-select-option :value="null" disabled>Все</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-group label="Версия бюджета">
                            <b-form-select
                                v-model="variant"
                                value-field="variant_uuid"
                                text-field="name_ru"
                                @change="changeFilter"
                                ref="variantRef"
                            >
                                <b-form-select-option v-for="vrn in listOfVariants" :value="vrn.variant_uuid"
                                                      :key="vrn.variant_uuid" :disabled="vrn.disabled">
                                    {{ getVariantName(vrn) }}
                                </b-form-select-option>
                                <template #first>
                                    <b-form-select-option :value="null" disabled>Все</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-group label="АБП">
                            <b-form-select
                                v-model="selectedABP"
                                :options="dictCost"
                                @change="selectedPRG = {id: 0, child: []}"
                            >
                                <template #first>
                                    <b-form-select-option :value="null">Все</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-group label="Программа(БП)" :disabled="(selectedABP.child == 0)">
                            <b-form-select
                                v-model="selectedPRG"
                                :options="selectedABP.child"
                                @change="selectedPPR = {id: 0}"
                            >
                                <template #first>
                                    <b-form-select-option :value="null">Все</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-group label="Подпрограмма (БПП)" :disabled="(selectedPRG.child == 0)">
                            <b-form-select
                                v-model="selectedPPR"
                                :options="selectedPRG.child"
                            >
                                <template #first>
                                    <b-form-select-option :value="{id: 0}">Все</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <b-button class="filter-add" variant="success"
                              v-show="(selectedPRG.id > 0)"
                              @click="addItem">+
                    </b-button>
                </div>
            </b-dropdown>
            <div v-if="is_variant_editable" class="filter-actions">
                <b-button :disabled="alertMessage!=''" @click="calculateCorrect">Применить
                    корректировку
                </b-button>
                <b-button :disabled="alertMessage!=''" variant="success" @click="saveDatas">Сохранить</b-button>
            </div>
        </div>
        <b-progress variant="primary" v-show="bar<100" height="3px" :value="bar" striped animated></b-progress>
        <div class="filter-breadcrumb">
                <span class="item-breadcrumb" v-if="repYear" @click="openFilterByRef('curYearRef')">
                    {{ repYear.toString() + ' - ' + (repYear+2).toString()  }}
                </span>
            <span class="item-breadcrumb" v-if="region" @click="openFilterByRef('regionRef')">
                    {{ region + ' - ' + getRegion(region).name_ru }}
                </span>
            <span class="item-breadcrumb" v-if="variant" @click="openFilterByRef('variantRef')">
                    {{ getVariantUidName(variant) }}-версия
                </span>
        </div>
        <b-alert :show="alertMessage!=''" variant="danger">{{ alertMessage }}</b-alert>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                :tbody-tr-class="rowClass"
                :filter="filter.search"
                :filter-included-fields="filter.on"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header
                no-border-collapse
            >
                <template #top-row="data">
                    <td v-for="(field, c) in data.fields.filter(v => ((v.thClass != 'dhNone') && (v.label != '')))"
                        :key="c + field" class="correctFirstRow">
                        <div class="text-center">
                            {{ c + 1 }}
                        </div>
                    </td>
                </template>
                <template #head(action)="scope">
                    <b-button @click="openAll()">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="open"></i>
                        <i class="icon icon-chevron-circle" v-if="!open"></i>
                    </b-button>
                </template>
                <!--                <template #head(abp)="scope">-->
                <!--                    <div>АБП</div>-->
                <!--                    <b-form-input-->
                <!--                        id="filter-input"-->
                <!--                        v-model="filter.abp"-->
                <!--                        type="search"-->
                <!--                        class="mini"-->
                <!--                    ></b-form-input>-->
                <!--                </template>-->
                <!--                <template #head(prg)="scope">-->
                <!--                    <div>БП</div>-->
                <!--                    <b-form-input-->
                <!--                        id="filter-input"-->
                <!--                        v-model="filter.prg"-->
                <!--                        type="search"-->
                <!--                        class="mini"-->
                <!--                    ></b-form-input>-->
                <!--                </template>-->
                <!--                <template #head(ppr)="scope">-->
                <!--                    <div>БПП</div>-->
                <!--                    <b-form-input-->
                <!--                        id="filter-input"-->
                <!--                        v-model="filter.ppr"-->
                <!--                        type="search"-->
                <!--                        class="mini"-->
                <!--                    ></b-form-input>-->
                <!--                </template>-->
                <template #head(name_ru)="scope">
                    <div>Наименование</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.name_ru"
                        type="search"
                    ></b-form-input>
                </template>
                <template #cell(action)="data">
                    <b-button v-if="data.item.type == 3" v-model="data.item.open"
                              @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(abp)="data">
                    <div v-if="data.item.type == 3">{{ data.value }}</div>
                    <b-button v-if="(data.item.type == 4) && (data.item.hasChild)" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(prg)="data">
                    <div v-if="data.item.type !== 5">{{ data.value }}</div>
                </template>
                <template #cell(ppr)="data">
                    <div class="text-right">{{ data.value }}</div>
                </template>
                <template #cell(is_correct_counted)="data">
                    <div>
                        <b-icon-check-circle v-if="data.item.is_correct_counted"
                                             variant="success"></b-icon-check-circle>
                    </div>
                </template>
                <template #cell(name_ru)="data">
                    <div>{{ data.value }}</div>
                </template>
                <template #cell(budget)="data">
                    <div v-if="data.item.is_correct_counted" class="text-right">
                        {{
                            (isNaN(data.value - data.item.total)) ? null : $n(data.value - data.item.total)
                        }}
                    </div>
                    <div v-else class="text-right">
                        {{ (isNaN(data.value)) ? null : $n(data.value) }}
                    </div>
                </template>
                <template #cell(abp_request)="data">
                    <!--                    &lt;!&ndash;                    {{data.item.budget +' : '+ data.item.total}}&ndash;&gt;-->
                    <!--                    <div v-if="data.item.is_correct_counted" class="text-right">-->
                    <!--                        {{-->
                    <!--                            (isNaN(data.value - data.item.total) || ((data.value - data.item.total) == 0)) ? null : $n(data.value - data.item.total)-->
                    <!--                        }}-->
                    <!--                    </div>-->
                    <div class="text-right">
                        {{ (isNaN(data.value)) ? null : $n(data.value) }}
                    </div>
                </template>
                <template #cell(approved_budget)="data">
                    <div class="text-right">
                        {{ (isNaN(data.value)) ? null : $n(data.value) }}
                    </div>
                </template>
                <template #cell(note)="data">
                    <template v-if="(is_variant_editable && data.item.editable)" class="p-1">
                        <b-form-textarea class="text-left h-100"
                                         size="sm"
                                         placeholder="Примечание..."
                                         :value="data.item[data.field.key]"
                                         @change="v => data.item[data.field.key] = v"
                                         @input="editing = true" rows="5">
                        </b-form-textarea>
                    </template>
                </template>
                <template #cell()="data">
                    <template v-if="(is_variant_editable && data.item.editable)">
                        <b-form-input class="text-right"
                                      :value="$n(data.item[data.field.key])"
                                      @change="v => data.item[data.field.key] = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="noAbc"
                                      @input="editing = true">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <diV class="text-right">
                            {{ (isNaN(data.value) || (data.value == 0)) ? null : $n(data.value) }}
                        </diV>
                    </template>
                </template>
                <template #cell(total)="data">
                    <div class="text-right">
                        {{ (isNaN(data.value) || (data.value == 0)) ? null : $n(data.value) }}
                    </div>
                </template>
                <template #cell(correct)="data">
                    <div v-if="data.item.is_correct_counted" class="text-right">
                        {{ (isNaN(data.value) || (data.value == 0)) ? null : $n(data.value - data.item.total) }}
                    </div>
                    <div v-else class="text-right">
                        {{ (isNaN(data.value) || (data.value == 0)) ? null : $n(data.value) }}
                    </div>
                </template>
                <template #cell(more)="data">
                    <b-button v-if="(is_variant_editable)" @click="deleteItem(data.item)">
                        <i class="icon icon-close"></i>
                    </b-button>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from '@/services/store';
import {BIconCheckCircle} from 'bootstrap-vue';
import {CUR_YEAR} from '@/modules/budget-request/components/js/budgetCurYear';
import {genListOfYears, listForCorrect, listForCorrectRegion, variantName} from '@/modules/budget/budgetCorrectHelper';
import CorrectMixin from './CorrectMixin'

export default {
    components: {BIconCheckCircle},
    name: 'CostCorrect',
    mixins: [CorrectMixin],
    props: {
        year: Number,
        obl: String,
        reg: String,
        listReg: Array,
        listVariants: Array
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ' ',
                    class: 'toggle-show'
                },
                {
                    key: 'abp',
                    label: 'АБП',
                    class: 'toggle-show'
                },
                {
                    key: 'prg',
                    label: 'БП'
                },
                {
                    key: 'ppr',
                    label: 'БПП'
                },
                {
                    key: 'is_correct_counted',
                    label: '+/-'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'approved_budget',
                    label: 'Утвержденный бюджет',
                    variant: 'danger'
                },
                {
                    key: 'budget',
                    label: 'Уточненный/ Скорректированный бюджет',
                    variant: 'danger'
                },
                {
                    key: 'abp_request',
                    label: 'Предложение АБП',
                    variant: 'danger'
                },
                {
                    key: 'filter',
                    label: 'filter',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ],
            calcFlds: [],
            repYear: this.year,
            ebkFunc: {dict: [], maps: {}},
            dictCost: [],

            selectedABP: {id: 0, child: []},
            selectedPRG: {id: 0, child: []},
            selectedPPR: {id: 0},
            selectedTree: [],
            budgetForm: [],
            variant: '',
            pprMap: new Map(),

            budget: [],
            bdata: {
                abp: new Set(),
                pprMap: new Map()
            },

            remove: {
                mark: false,
                values: []
            },

            filter: {
                abp: null,
                prg: null,
                ppr: null,
                name_ru: null,
                search: null,
                on: ['filter', 'abp', 'prg', 'ppr', 'name_ru']
            },
            open: true,
            editing: false,
            msg: false,
            active: null,
            bar: 0,
            region: null
        };
    },

    created() {
        this.$watch('remove.mark', this.deleteDatas);
        this.$watch('filter.abp', this.filterUpdate);
        this.$watch('filter.prg', this.filterUpdate);
        this.$watch('filter.ppr', this.filterUpdate);
        this.$watch('filter.name_ru', this.filterUpdate);
        // this.$watch('reg', this.region = this.reg);
    },

    async mounted() {
        this.getFields();
        this.bar = 10;
        //       this.loadBudget();
        this.bar = 20;
        // await this.loadEbkFunc();
        this.bar = 30;
        // await this.loadDatas();
        this.bar = 100;
    },

    beforeUpdate() {
        this.budgetForm.forEach(row => {
            if (row.type == 0) {
                row._rowVariant = 'danger';
            }
            if ((row.type == 3) || ((row.type == 4) && row.hasChild)) {
                row._rowVariant = 'info';
            }
            if ((row.type == 5) || ((row.type == 4) && !row.hasChild)) {
                this.$set(row, 'editable', true);
            }
        });
    },

    watch: {
        region: function(oldVal, newVal) {
            if (oldVal!=newVal) {
                this.variant = '';
            }
        },
    },

    computed: {
        alertMessage() {
            const res = [];
            // for (const item of this.budgetForm) {
            //     if ((item.total != 0) && (item.note == '')) {
            //         res.push('Нет примечания по коду: ' + item.abp + '-' + item.prg + '-' + item.ppr);
            //     }
            // }
            return res.toString();
        },
    },

    methods: {
        getVariantName(variant) {
            return variantName(variant);
        },
        async calculateCorrect() {
            try {
                const response = await fetch('/api-py/cost-correct-counting/'
                    + this.variant + '/' + this.region);
                await response.json();
                this.budgetForm = [];
                this.variant = '';
            } catch (error) {
                this.makeToast('danger', 'Ошибка применения корректировки', error.toString());
            }
        },

        async getNewItemBudgetData(dictItem) {
            try {
                const response = await fetch('/api-py/get-cost-correct-by-yrv/'
                    + this.repYear + '/' + this.region + '/' + this.variant + '/' + dictItem.abp + '/' + dictItem.prg
                    + '/' + dictItem.ppr);
                return await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getFields', error.toString());
            }
        },

        async getNewItemBudgetDataNoPpr(dictItem) {
            try {
                const response = await fetch('/api-py/get-cost-correct-by-yrv/'
                    + this.repYear + '/' + this.region + '/' + this.variant + '/' + dictItem.abp + '/' + dictItem.prg);
                return await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getFields', error.toString());
            }
        },

        async createNewAddedPpr(dictItem) {
            const newPPR = Object.assign({}, dictItem);
            const bdat = await this.getNewItemBudgetData(dictItem);
            console.log('btat', bdat[0])
            this.$set(newPPR, 'note', '');
            this.$set(newPPR, 'approved_budget', bdat[0].approved_budget);
            this.$set(newPPR, 'abp_request', bdat[0].abp_request);
            this.$set(newPPR, 'budget', bdat[0].budget);
            return newPPR;
        },

        async createNewAddedNoPpr(dictItem) {
            const newPPR = Object.assign({}, dictItem);
            const bdat = await this.getNewItemBudgetDataNoPpr(dictItem);
            console.log('btat', bdat[0])
            this.$set(newPPR, 'note', '');
            this.$set(newPPR, 'approved_budget', bdat[0].approved_budget);
            this.$set(newPPR, 'abp_request', bdat[0].abp_request);
            this.$set(newPPR, 'budget', bdat[0].budget);
            return newPPR;
        },

        async addItem() {
            const that = this;

            const abpExist = that.selectedTree.filter(function (item) {
                if (item.abp == that.selectedABP.abp) {
                    return item;
                }
            });
            if (abpExist.length == 0) { // Если АБП нет в выбранном Источнике
                const newABP = Object.assign({}, that.selectedABP);
                that.$set(newABP, 'child', []);
                let newPRG = Object.assign({}, that.selectedPRG);
                that.$set(newPRG, 'child', []);
                if (that.selectedPPR.id > 0) {
                    const newPPR = await this.createNewAddedPpr(that.selectedPPR); // Object.assign({}, that.selectedPPR);
                    newPRG.child.push(newPPR);
                } else {
                    if (that.selectedPRG.child.length == 0) {
                        newPRG = await this.createNewAddedNoPpr(newPRG)
                    }
                    for (const ppr of that.selectedPRG.child) {
                        const newPPR = await this.createNewAddedPpr(ppr.value);
                        newPRG.child.push(newPPR);
                    }
                }
                newABP.child.push(newPRG);
                that.selectedTree.push(newABP);
            } else { // Если АБП есть в выбранном Источнике
                const prgExist = abpExist[0].child.filter(function (item) {
                    if (item.id == that.selectedPRG.id) {
                        return item;
                    }
                });
                if (prgExist.length == 0) { // Если АБП есть в выбранном Источнике + ПРГ нет в АБП
                    let newPRG = Object.assign({}, that.selectedPRG);
                    that.$set(newPRG, 'child', []);
                    if (that.selectedPPR.id > 0) {
                        const newPPR = await this.createNewAddedPpr(that.selectedPPR);
                        newPRG.child.push(newPPR);
                    } else {
                        if (that.selectedPRG.child.length == 0) {
                            newPRG = await this.createNewAddedNoPpr(newPRG)
                        }
                        for (const ppr of that.selectedPRG.child) {
                            const newPPR = await this.createNewAddedPpr(ppr.value);
                            newPRG.child.push(newPPR);
                        }
                    }
                    abpExist[0].child.push(newPRG);
                } else { // Если АБП есть в выбранном Источнике + ПРГ есть в АБП
                    if (that.selectedPPR.id > 0) { // если ППР выбран
                        const pprExist = prgExist[0].child.filter(function (item) {
                            if (item.id == that.selectedPPR.id) {
                                return item;
                            }
                        });
                        if (pprExist.length == 0) { // Если АБП есть в выбранном Источнике + ПРГ есть в АБП + PPR нет в ПРГ
                            const newPPR = await this.createNewAddedPpr(that.selectedPPR);
                            prgExist[0].child.push(newPPR);
                        } else { // если ПРГ
                            that.makeToast('warning', 'Предупреждение',
                                'Подпрограмма \"' + that.selectedPPR.name_ru + '\" - уже добавлена в таблицу');
                            return;
                        }
                    } else { // если выбран весь ПРГ
                        let added = false;
                        if (that.selectedPRG.child.length == 0) {
                            alert('No prg')
                            // newPRG = await this.createNewAddedNoPpr(newPRG)
                        }
                        for (const ppr of that.selectedPRG.child) {
                            const pprExist = prgExist[0].child.filter(function (item) {
                                if (ppr.value.id == item.id) {
                                    return item;
                                }
                            });
                            if (pprExist.length == 0) {
                                const newPPR = await this.createNewAddedPpr(ppr.value);
                                prgExist[0].child.push(newPPR);
                                added = true;
                            }
                        }
                        if (!added) {
                            that.makeToast('warning', 'Предупреждение',
                                'Программа \"' + that.selectedPRG.name_ru + '\" - уже добавлена в таблицу');
                            return;
                        }
                    }
                }
            }
            that.prepareDatas();
        }, // добавляет выбранное значение из фильтра в дерево

        compareDatas() {
            const that = this;

            const saveDatas = [];
            for (const row of that.pprMap.values()) {
                const val = that.bdata.pprMap.get(that.getRowKey(row, (row.type == 4 ? ['abp', 'prg'] : ['abp', 'prg', 'ppr'])));

                let bool = true;
                if (val !== undefined) {
                    that.calcFlds.forEach(fld => {
                        bool = bool && (row[fld] == val[fld]);
                    });
                }

                if ((val == undefined)
                    || ((val !== undefined) && !bool)) {
                    for (const fld of that.calcFlds) {
                        if (row[fld] !== 0) {
                            const cost = {
                                gr: row.gr,
                                pgr: row.pgr,
                                abp: row.abp,
                                prg: row.prg,
                                ppr: row.ppr,
                                field: fld.split('_')[1],
                                value: parseFloat(row[fld]),
                                year: this.year,
                                region: that.region,
                                variant: that.variant,
                                note: row.note,
                                user_name: that.userLogin()
                            };
                            saveDatas.push(cost);
                        }
                    }
                }
            }
            return saveDatas;
        }, // сравнивает введенные данные с ранее сохраненные и формирует массив новых записей для сохранения в БД

        createTable(elem, parent_id) {
            const that = this;

            try {
                const item = Object.assign({}, elem);
                that.$set(item, 'parent_id', parent_id);
                that.$set(item, 'visible', true);

                Object.defineProperty(item, 'parent', {
                    get: function () {
                        for (const row of that.budgetForm) {
                            if (item.parent_id == row.id) {
                                return row;
                            }
                        }
                    }
                });
                Object.defineProperty(item, 'total', {
                    get: function () {
                        let sum = 0;
                        for (const fld of that.calcFlds) {
                            sum += parseFloat(item[fld]);
                        }
                        return sum;
                    }
                });
                Object.defineProperty(item, 'correct', {
                    get: function () {
                        let sum = 0;
                        for (const fld of that.calcFlds) {
                            sum += parseFloat(item[fld]);
                        }
                        return sum + parseFloat(item.budget);
                    }
                });
                if (item.type > 4 || (item.type == 4 && !item.pprChild)) {
                    if (!item.hasOwnProperty('budget')) {
                        that.$set(item, 'budget', 0);
                    }
                    if (!item.hasOwnProperty('abp_request')) {
                        that.$set(item, 'abp_request', 0);
                    }
                    if (!item.hasOwnProperty('approved_budget')) {
                        that.$set(item, 'approved_budget', 0);
                    }
                    for (const fld of that.calcFlds) {
                        that.$set(item, fld, 0);
                    }

                    that.pprMap.set(that.getRowKey(item, (item.ppr ? ['abp', 'prg', 'ppr'] : ['abp', 'prg'])), item);
                } else {
                    that.$set(item, 'open', true);
                    that.$set(item, 'hasChild', true);
                    Object.defineProperty(item, 'budget', {
                        get: function () {
                            return that.reSum(item, 'budget');
                        }
                    });

                    Object.defineProperty(item, 'abp_request', {
                        get: function () {
                            return that.reSum(item, 'abp_request');
                        }
                    });
                    Object.defineProperty(item, 'approved_budget', {
                        get: function () {
                            return that.reSum(item, 'approved_budget');
                        }
                    });
                    that.calcFlds.forEach(fld => {
                        Object.defineProperty(item, fld, {
                            get: function () {
                                return that.reSum(item, fld);
                            }
                        });
                    });
                }
                that.$set(item, 'index', that.budgetForm.length);
                that.$set(that.budgetForm, that.budgetForm.length, item);
                if ((item.hasChild)) {
                    for (const ch of item.child) {
                        that.createTable(ch, item.id);
                    }
                    delete item.child;
                }
            } catch (error) {
                that.makeToast('danger', 'Ошибка createTable', error.toString() + ':' + error.lineNumber);
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        deleteItem(item) {
            const that = this;

            that.$bvModal.msgBoxConfirm(
                'Подтвердите удаление: \"' + item.name_ru + '\" ',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        // item == abp
                        if ((item.abp !== null) && (item.prg == null) && (item.ppr == null)) {
                            that.delFromArray(that.selectedTree, item);
                        }
                        // item == prg
                        if ((item.prg !== null) && (item.ppr == null)) {
                            const abp = that.selectedTree.filter(function (obj) {
                                if (obj.id == item.parent.id) {
                                    return obj;
                                }
                            });
                            if (abp[0].child.length == 1) {
                                that.delFromArray(that.selectedTree, abp[0]);
                            } else {
                                that.delFromArray(abp[0].child, item);
                            }
                        }
                        // item == ppr
                        if (item.ppr !== null) {
                            const abp = that.selectedTree.filter(function (obj) {
                                if (obj.id == item.parent.parent.id) {
                                    return obj;
                                }
                            });
                            const prg = abp[0].child.filter(function (obj) {
                                if (obj.id == item.parent.id) {
                                    return obj;
                                }
                            });
                            if (prg[0].child.length > 1) {
                                that.delFromArray(prg[0].child, item);
                            } else {
                                if (abp[0].child.length == 1) {
                                    that.delFromArray(that.selectedTree, abp[0]);
                                } else {
                                    that.delFromArray(abp[0].child, prg[0]);
                                }
                            }
                        }
                        that.prepareDatas();
                    }
                })
                .catch(error => {
                    that.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        }, // удаляет элемент

        async deleteDatas() {
            if (this.remove.values.length > 0) {
                const response = await fetch('/api-py/delete-cost-correct', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.remove.values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.makeToast('success', 'Сообщение', 'Элемент удален');
                }
            }
            this.remove = {mark: false, values: [], projects: []};
        }, // удаляет, ранее помеченные записи из БД

        deleteFromTable(item) {
            const that = this;
            if (item.type == 5) {
                const cost = {
                    gr: parseInt(item.gr),
                    pgr: parseInt(item.pgr),
                    abp: parseInt(item.abp),
                    prg: parseInt(item.prg),
                    ppr: parseInt(item.ppr),
                    region: that.region,
                    year: that.year,
                    variant: that.variant
                };
                that.remove.values.push(cost);
            } else {
                for (const row of that.pprMap.values()) {
                    if ((item.type == 3)
                        && (row.abp == item.abp)) {
                        const cost = {
                            gr: parseInt(row.gr),
                            pgr: parseInt(row.pgr),
                            abp: parseInt(row.abp),
                            prg: parseInt(row.prg),
                            ppr: (row.type == 5 ? parseInt(row.ppr) : null),
                            region: that.region,
                            year: that.year,
                            variant: that.variant
                        };
                        that.remove.values.push(cost);
                    }
                    if ((item.type == 4)
                        && (row.abp == item.abp)
                        && (row.prg == item.prg)) {
                        const cost = {
                            gr: parseInt(row.gr),
                            pgr: parseInt(row.pgr),
                            abp: parseInt(row.abp),
                            prg: parseInt(row.prg),
                            ppr: (row.type == 5 ? parseInt(row.ppr) : null),
                            region: that.region,
                            year: that.year,
                            variant: that.variant
                        };
                        that.remove.values.push(cost);
                    }
                }
            }
            that.remove.mark = true;
        }, // удаляет элемент из таблицы

        delFromArray(arr, item) {
            arr.forEach((el, index) => {
                if (item.id == el.id) {
                    arr.splice(index, 1);
                }
            });
            this.deleteFromTable(item);
        }, // удаляет элемент из дерева

        filterUpdate() {
            this.filter.search = (this.filter.abp == null ? '' : this.filter.abp)
                + (this.filter.prg == null ? '' : this.filter.prg)
                + (this.filter.ppr == null ? '' : this.filter.ppr)
                + (this.filter.name_ru == null ? '' : this.filter.name_ru);
            if (this.filter.search.length == 0) {
                this.filter.search = null;
            }
        }, // формирует строку поиска

        focusout() {
            if (!this.editing) {
                return;
            }
            const res = this.compareDatas();
            if (res.length > 0) {
                this.$bvModal.msgBoxConfirm(
                    'Сохранить введенные данные?',
                    {
                        title: 'Подтверждение',
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'YES',
                        cancelTitle: 'NO',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                        if (value) {
                            this.saveVariant(res);
                        } else {
                            this.editing = false;
                        }
                    })
                    .catch(error => {
                        this.makeToast('danger', 'Ошибка сохранения', error.toString());
                    });
            }
        }, // срабатывает запрос на сохранения данных, при потере фокуса области ввода

        async getFields() {
            let items = [];
            try {
                const response = await fetch('/api-py/dictionary/budget_adjust/');
                items = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getFields', error.toString());
                return;
            }
            for (const val of items) {
                this.tableFields.push({
                    key: 'field_' + val.code,
                    label: val.name_ru
                });
                this.calcFlds.push('field_' + val.code);
            }
            this.tableFields.push({
                key: 'total',
                label: 'Всего изменений'
            });
            this.tableFields.push({
                key: 'correct',
                label: 'Скорректированный бюджет'
            });
            this.tableFields.push(
                {
                    key: 'note',
                    label: 'Примечание',
                    variant: 'danger'
                }
            );
            this.tableFields.push({
                key: 'more',
                label: ''
            });
        }, // добавляем в таблицу динамические поля

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadBudget() {
            if (this.variant != '') {
                try {
                    // const prev_variant = 'f1340fa7-e90d-42a2-92ae-a6055dc1d278';
                    const response = await fetch('/api-py/get-budget-data-by-yrv/'
                        + this.year + '/' + this.region + '/' + this.variant);
                    this.budget = await response.json();
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса loadBudget()', error.toString());
                }
            } else {
                this.makeToast('warning', 'Предупрждение', 'Не выбран вариант');
            }
        }, // данные утвержденного бюджета из BudgetData

        async loadEbkFunc() {
            try {
                const response = await fetch('/api-py/get-dict-func');
                this.ebkFunc.dict = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadEbkFunc', error.toString());
            }
        }, // справочник EbkFunc

        async changeFilter() {
            const filter = {
                variant: this.variant,
                year: this.repYear,
                region: this.region
            };

            this.$emit('change_filter', filter);
            this.selectedABP = {id: 0, child: []};
            this.selectedPRG = {id: 0, child: []};
            this.selectedPPR = {id: 0};
            this.selectedTree = [];
            if ((this.variant!='') && (this.region!=null)) {
                this.loadDatas();
            }
        },

        async loadDatas() {
            if (this.ebkFunc.dict.length == 0) {
                this.bar = 30;
                await this.loadEbkFunc();
            }
            this.dictCost.splice(0);
            this.budgetForm.splice(0);
            await this.loadBudget();
            this.pprMap.clear();

            this.bdata.abp.clear();
            this.bdata.pprMap.clear();

            await this.loadDict();
            this.bar = 50;
            await this.loadUserDatas();
            this.bar = 100;
        }, // загрузка данных при смене региона

        async loadDict() {
            const that = this;

            const abpMap = new Map();
            const prgMap = new Map();
            const pprMap = new Map();

            let levels = [];
            if (that.region.endsWith('0101')) {
                levels = [2];
            } else {
                levels = [3, 4];
            }

            const promise = new Promise(function (resolve, reject) {
                for (const row of that.ebkFunc.dict) {
                    that.$set(row, 'filter', row.abp
                        + (row.prg == null ? '' : that.padLeadingZeros(row.prg, 3))
                        + (row.ppr == null ? '' : that.padLeadingZeros(row.ppr, 3))
                        + row.name_ru);

                    if (row.type < 5) {
                        that.$set(row, 'child', []);
                    }
                    if ((row.type == 3) && (levels.includes(row.budget_level_id))) {
                        abpMap.set(that.getRowKey(row, ['abp']),
                            that.newElem('abp', row.abp, row.abp + ' - ' + row.name_ru, row));
                    }
                    if (row.type == 4) {
                        row.prg = that.padLeadingZeros(row.prg, 3);
                        prgMap.set(that.getRowKey(row, ['abp', 'prg']),
                            that.newElem('prg', row.prg, row.prg + ' - ' + row.name_ru, row));
                    }
                    if (row.type == 5) {
                        row.ppr = that.padLeadingZeros(row.ppr, 3);
                        pprMap.set(that.getRowKey(row, ['abp', 'prg', 'ppr']),
                            that.newElem('ppr', row.ppr, row.ppr + ' - ' + row.name_ru, row));
                    }
                }
                resolve({abpMap: abpMap, prgMap: prgMap, pprMap: pprMap});
            });
            promise.then(
                result => {
                    that.ebkFunc.maps = result;
                    for (const ppr of result.pprMap.values()) {
                        const prg = result.prgMap.get(that.getRowKey(ppr.value, ['abp', 'prg']));
                        if (prg !== undefined) {
                            that.$set(prg.value, 'pprChild', true);
                            prg.value.child.push(ppr);
                        }
                    }
                    for (const prg of result.prgMap.values()) {
                        const abp = result.abpMap.get(that.getRowKey(prg.value, ['abp']));
                        if (abp !== undefined) {
                            prg.value.child.sort(that.sortByField('ppr'));
                            abp.value.child.push(prg);
                        }
                    }
                    for (const abp of result.abpMap.values()) {
                        abp.value.child.sort(that.sortByField('prg'));
                        that.dictCost.push(abp);
                    }
                    that.dictCost.sort(that.sortByField('abp'));
                },
                error => {
                    that.makeToast('danger', 'Ошибка loadDict', error.toString());
                }
            );
        }, // собирает справочник EbkFunc ввиде дерева с помощью Map-ов

        async loadUserDatas() {
            if (this.variant == '') {
                this.makeToast('warning', 'Предупреждение', 'Не выбран вариант');
                return
            }
            const that = this;

            let correct = [];
            try {
                const response = await fetch('/api-py/get-cost-correct-by-yrv/'
                    + that.repYear + '/' + that.region + '/' + that.variant);
                correct = await response.json();
            } catch (error) {
                that.makeToast('danger', 'Ошибка запроса loadUserDatas', error.toString());
                return;
            }

            if (correct.length == 0) {
                this.bar = 100;
                return;
            }

            const abpMap = new Map();
            const prgMap = new Map();
            const pprMap = new Map();
            const user = that.userLogin();

            const promise = new Promise(function (resolve, reject) {
                for (const val of correct) {
                    that.bdata.abp.add(val.abp);
                    const bd = that.bdata.pprMap.get(that.getRowKey(val, (val.ppr == null ? ['abp', 'prg'] : ['abp', 'prg', 'ppr'])));
                    if (bd == undefined) {
                        that.$set(val, 'field_' + val.field, val.value);
                        that.bdata.pprMap.set(that.getRowKey(val, (val.ppr == null ? ['abp', 'prg'] : ['abp', 'prg', 'ppr'])), val);
                    } else {
                        that.$set(bd, 'field_' + val.field, val.value);
                    }
                    if (true) {
                        const a = that.ebkFunc.maps.abpMap.get(that.getRowKey(val, ['abp']));
                        if (a !== undefined) {
                            const abp = Object.assign({}, a.value);
                            that.$set(abp, 'child', []);
                            abpMap.set(that.getRowKey(val, ['abp']), abp);
                        }

                        const pr = that.ebkFunc.maps.prgMap.get(that.getRowKey(val, ['abp', 'prg']));
                        if (pr !== undefined) {
                            const prg = Object.assign({}, pr.value);
                            that.$set(prg, 'child', []);
                            prgMap.set(that.getRowKey(val, ['abp', 'prg']), prg);
                        }

                        const pp = that.ebkFunc.maps.pprMap.get(that.getRowKey(val, ['abp', 'prg', 'ppr']));
                        if (pp !== undefined) {
                            const ppr = Object.assign({}, pp.value);
                            pprMap.set(that.getRowKey(val, ['abp', 'prg', 'ppr']), ppr);
                        }
                    }
                }
                resolve({abpMap: abpMap, prgMap: prgMap, pprMap: pprMap});
            });
            promise.then(
                result => {
                    for (const ppr of result.pprMap.values()) {
                        const prg = result.prgMap.get(that.getRowKey(ppr, ['abp', 'prg']));
                        if (prg !== undefined) {
                            prg.child.push(ppr);
                        }
                    }
                    for (const prg of result.prgMap.values()) {
                        const abp = result.abpMap.get(that.getRowKey(prg, ['abp']));
                        if (abp !== undefined) {
                            abp.child.push(prg);
                        }
                    }
                    for (const abp of result.abpMap.values()) {
                        let found = false;
                        for (const st of that.selectedTree) {
                            if (abp.id == st.id) {
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            abp.child.sort(that.sortByField('prg'));
                            that.selectedTree.push(abp);
                        }
                    }
                    that.prepareDatas();
                },
                error => {
                    that.makeToast('danger', 'Ошибка loadUserDatas', error.toString());
                }
            );
        }, // формирует дерево из последних данных, введенных данным пользователем

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, //  сообщение

        newElem(field, code, text, value) {
            const el = {};
            this.$set(el, field, code);
            this.$set(el, 'text', text);
            this.$set(el, 'value', value);
            return el;
        }, // формирует элемент для отображения в фильтрах

        noAbc: function (evt) {
            // const regex = new RegExp('^-?[0-9]+$');\.?
            const regex = new RegExp('^-?\\d*\\d{0,9}$');
            const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
            if (!regex.test(key)) {
                evt.preventDefault();
                return false;
            }
        }, // вводит только цифры

        openAll() {
            this.open = !this.open;

            for (const row of this.budgetForm) {
                if (row.type > 0) {
                    row.visible = this.open;
                    if ([3, 4].includes(row.type)) {
                        row.open = this.open;
                    }
                    if ([0, 3].includes(row.type)) {
                        row.visible = true;
                    }
                }
            }
        }, // открывает.закрывает все ветки

        openChilds(parent, bool) {
            parent.open = (bool == undefined ? !parent.open : bool);

            for (const row of this.budgetForm) {
                if (parent.id == row.parent_id) {
                    if ([3, 4].includes(row.type)) {
                        this.openChilds(row, parent.open);
                    }
                    row.visible = parent.open;
                }
            }
        }, // открывает/закрывает ветку до конечного элемента

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) {
                s = '0' + s;
            }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        prepareDatas() {
            const that = this;

            that.budgetForm = [];
            const promise = new Promise(function (resolve, reject) {
                that.selectedTree.sort(that.sortByField('abp'));
                for (const item of that.selectedTree) {
                    that.createTable(item, 0);
                    that.bar += 10;
                }

                // добавляем итоговую строку
                const total = {
                    id: 0,
                    abp: 0,
                    type: 0,
                    name_ru: 'Итого',
                    parent_id: -1,
                    visible: true
                };

                Object.defineProperty(total, 'budget', {
                    get: function () {
                        return that.reSum(total, 'budget');
                    }
                });
                Object.defineProperty(total, 'abp_request', {
                    get: function () {
                        return that.reSum(total, 'abp_request');
                    }
                });
                Object.defineProperty(total, 'approved_budget', {
                    get: function () {
                        return that.reSum(total, 'approved_budget');
                    }
                });

                that.calcFlds.forEach(fld => {
                    Object.defineProperty(total, fld, {
                        get: function () {
                            return that.reSum(total, fld);
                        }
                    });
                });
                Object.defineProperty(total, 'total', {
                    get: function () {
                        let sum = 0;
                        for (const fld of that.calcFlds) {
                            sum += parseFloat(total[fld]);
                        }
                        return parseFloat(sum);
                    }
                });
                Object.defineProperty(total, 'correct', {
                    get: function () {
                        let sum = 0;
                        for (const fld of that.calcFlds) {
                            sum += parseFloat(total[fld]);
                        }
                        return parseFloat(total.budget + sum);
                    }
                });

                that.$set(total, 'index', that.budgetForm.length);
                that.$set(that.budgetForm, that.budgetForm.length, total);
                // добавление закончилось

                resolve(true);
            });
            promise.then(
                result => {
                    that.relevant();
                    that.open = result;
                    that.openAll();

                    if (that.selectedABP.id > 0) {
                        for (const row of that.budgetForm) {
                            if ((row.abp == that.selectedABP.abp)
                                && (row.type == 3)) {
                                row.open = false;
                                that.openChilds(row);

                                if (that.active !== null) {
                                    const input = document.getElementById(that.active);
                                    if (input !== null) {
                                        input.select();
                                    }
                                    that.active = null;
                                }
                                return;
                            }
                        }
                    }
                },
                error => {
                    that.makeToast('danger', 'Ошибка prepareDatas', error.toString());
                }
            );
        }, // подготовка отображения данных

        async relevant() {
            const that = this;
            // выставляем значения утвержденного бюджета
            await that.budget.forEach(val => {
                const ppr = that.pprMap.get(that.getRowKey(val, (val.ppr == null ? ['abp', 'prg'] : ['abp', 'prg', 'ppr'])));
                if (ppr !== undefined) {
                    console.log('val1', val)
                    that.$set(ppr, 'budget', val.value);
                    // that.$set(ppr, 'abp_request', val.abp_request);
                    // that.$set(ppr, 'approved_budget', val.approved_budget);
                    // that.$set(ppr, 'note', val.note);
                }
            });
            if ((that.selectedABP.id > 0) && !(that.bdata.abp.has(that.selectedABP.abp))) {
                try {
                    const response = await fetch('/api-py/get-cost-correct-by-yrvabp/'
                        + that.year + '/' + that.region + '/' + that.variant + '/'
                        + that.selectedABP.abp);
                    const values = await response.json();

                    that.bdata.abp.add(that.selectedABP.abp);

                    for (const val of values) {
                        const bd = that.bdata.pprMap.get(that.getRowKey(val, (val.ppr == null ? ['abp', 'prg'] : ['abp', 'prg', 'ppr'])));
                        if (bd == undefined) {
                            that.$set(val, 'field_' + val.field, val.value);
                            that.bdata.pprMap.set(that.getRowKey(val, (val.ppr == null ? ['abp', 'prg'] : ['abp', 'prg', 'ppr'])), val);
                        } else {
                            that.$set(bd, 'field_' + val.field, val.value);
                        }
                    }
                } catch (error) {
                    that.makeToast('danger', 'Ошибка запроса данных relevant()', error.toString());
                    return;
                }
            }

            // выставляем значения расходов
            for (const ppr of that.pprMap.values()) {
                const val = that.bdata.pprMap.get(that.getRowKey(ppr, (ppr.type == 4 ? ['abp', 'prg'] : ['abp', 'prg', 'ppr'])));
                if (val !== undefined) {
                    console.log('val', val)
                    that.$set(ppr, 'is_correct_counted', val.is_correct_counted);
                    that.$set(ppr, 'abp_request', (val.abp_request) ? val.abp_request : 0);
                    that.$set(ppr, 'approved_budget', (val.approved_budget) ? val.approved_budget : 0);
                    that.$set(ppr, 'note', (val.note) ? val.note : '');
                    for (const fld of that.calcFlds) {
                        that.$set(ppr, fld, (val[fld] == undefined || val[fld] == null) ? 0 : val[fld]);
                    }
                }
            }
        }, // расставляет сохранненные данные по полям

        reSum(parent, field) {
            let sum = 0;
            this.budgetForm.forEach(row => {
                if ((parent.id == row.parent_id)
                    && (parent.type != row.type)) {
                    if (row[field]) {
                        sum += parseFloat(row[field]);
                    }
                    if ((field == 'budget') && (row.is_correct_counted)) {
                        sum = sum - row.total;
                    }
                }
            });
            return parseFloat(sum.toFixed(2));
        }, // пересчет суммы

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
            if ((item.total != 0) && (item.note == '')) {
                item._rowVariant = 'warning';
            } else {
                item._rowVariant = '';
            }
        }, // задает класс 'is-hidden' заданной строке

        saveDatas() {
            const res = this.compareDatas();
            if (res.length > 0) {
                this.saveVariant(res);
            }
        }, // вызывает сохранение записей

        async saveVariant(item) {
            const that = this;
            const response = await fetch('/api-py/save-cost-correct', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(item)
            });
            const result = await response.json();
            if ((response.status == 200) && (result.result == 'success')) {
                for (const ppr of that.pprMap.values()) {
                    const obj = Object.assign({}, ppr);
                    that.bdata.pprMap.set(that.getRowKey(ppr, (ppr.type == 4 ? ['abp', 'prg'] : ['abp', 'prg', 'ppr'])), obj);
                }
                that.makeToast('success', 'Сохранение', 'Данные сохранены');
            } else {
                that.makeToast('danger', 'Ошибка сохранения', result.result);
            }
        }, // сохранение программ в БД

        sortByField(field) {
            return (a, b) => (a[field] > b[field] ? 1 : -1);
        }, // сортирует по заданному полю

        userLogin() {
            return store.state.user.login;
        }, // имя пользвателя
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        }
    }
};
</script>
<style scoped>
.correctFirstRow {
    background-color: #F7F9FC;
    padding: 4px;
    color: #6087A0 !important;
}
</style>